import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ScrollAnimation from 'react-animate-on-scroll';
import ReactMarkdown from 'react-markdown';
import Header from '../components/header';
import SEO from "../components/seo";
import Footer from '../components/footer';
import Contact from '../components/contact';
import OfficeMap from '../components/officeMap';

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="關於光群" />
    <Header />
    <Contact />
    <section>
      <div className="video-banner">
        <video 
          autoPlay
          muted
          loop
          style={{ height:"100vh", width:"100%", objectFit:"cover", position:"absolute" }}
        >
          <source src="/static/videos/about_background_udaekp.mp4" type="video/mp4"></source>
        </video>
        <div className="banner-second-main" >
          <h1 className="main-banner-title">永續經營<br />歷久彌新</h1>
        </div>
      </div>
    </section>

    <div className="page-content">
      <section className="section">
        <div className="w-container">
          <div className="w-row">
            <div className="w-col w-col-12 w-col-stack">
              <div className="w-dyn-list">
                {data.allStrapiAbout.edges.map(document => (
                <div key={document.node.id}> 
                  {(document.node.language === "Chinese") ?
                  <div className="w-dyn-items">
                    <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                      <ReactMarkdown source={document.node.description} />
                    </ScrollAnimation>
                  </div> : <span />}
                </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section zero-all-padding" style={{ background:"#414141"}}>
        <div className="map"><OfficeMap /></div>
      </section>
      
      <section id="contact" className="section more-padding">
        <div className="w-container">
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
            <div className="w-row">
              <div className="w-col w-col-2">
                <h4 className="event-office-title">Headquarter</h4>
              </div>
              <div className="w-col w-col-10">
                <div className="w-row">
                {data.allStrapiOffice.edges.map(document => (
                  <div key={document.node.id}>
                  {(document.node.region === "Global") ?
                    <div className="w-col w-col-6">
                      <div className="office-content">
                        <h4 className="event-office-title">{document.node.name}</h4>
                        <p className="event-office-text">{document.node.address}<br />{document.node.address_line_two}<br />{document.node.city}, {document.node.state}, {document.node.zipcode}, {document.node.country}</p>
                        <p className="event-office-text">Tel: {document.node.phone}</p>
                      </div>
                    </div> : <span />}
                  </div> 
                ))}
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
            <div className="w-row">
              <div className="w-col w-col-2">
                <h4 className="event-office-title">Americas</h4>
              </div>
              <div className="w-col w-col-10">
                <div className="w-row">
                {data.allStrapiOffice.edges.map(document => (
                  <div key={document.node.id}>
                  {(document.node.region === "Americas") ?
                    <div className="w-col w-col-6">
                      <div className="office-content">
                        <h4 className="event-office-title">{document.node.name}</h4>
                        <p className="event-office-text">{document.node.address}<br />
                          {document.node.address_line_two}{document.node.address_line_two ? <br /> : <span />}
                          {document.node.city}{document.node.city ? ", " :" "}  
                          {document.node.state}{document.node.state ? ", " :" "}  
                          {document.node.zipcode}{document.node.zipcode ? ", " :" "} 
                          {document.node.country}
                        </p>
                        <p className="event-office-text">Tel: {document.node.phone}</p>
                      </div>
                    </div> : <span />}
                  </div> 
                ))}
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
            <div className="w-row">
              <div className="w-col w-col-2">
                <h4 className="event-office-title">China</h4>
              </div>
              <div className="w-col w-col-10">
                <div className="w-row">
                {data.allStrapiOffice.edges.map(document => (
                  <div key={document.node.id}>
                  {(document.node.region === "China") ?
                    <div className="w-col w-col-6">
                      <div className="office-content">
                        <h4 className="event-office-title">{document.node.name}</h4>
                        <p className="event-office-text">{document.node.address}<br />
                          {document.node.address_line_two}{document.node.address_line_two ? <br /> : <span />}
                          {document.node.city}{document.node.city ? ", " :" "}  
                          {document.node.state}{document.node.state ? ", " :" "}  
                          {document.node.zipcode}{document.node.zipcode ? ", " :" "} 
                          {document.node.country}
                        </p>
                        <p className="event-office-text">Tel: {document.node.phone}</p>
                      </div>
                    </div> : <span />}
                  </div> 
                ))}
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
            <div className="w-row">
              <div className="w-col w-col-2">
                <h4 className="event-office-title">IMEA</h4>
              </div>
              <div className="w-col w-col-10">
                <div className="w-row">
                {data.allStrapiOffice.edges.map(document => (
                  <div key={document.node.id}>
                  {(document.node.region === "IMEA") ?
                    <div className="w-col w-col-6">
                      <div className="office-content">
                        <h4 className="event-office-title">{document.node.name}</h4>
                        <p className="event-office-text">{document.node.address}<br />
                          {document.node.address_line_two}{document.node.address_line_two ? <br /> : <span />}
                          {document.node.city}{document.node.city ? ", " :" "}  
                          {document.node.state}{document.node.state ? ", " :" "}  
                          {document.node.zipcode}{document.node.zipcode ? ", " :" "} 
                          {document.node.country}
                        </p>
                        <p className="event-office-text">Tel: {document.node.phone}</p>
                      </div>
                    </div> : <span />}
                  </div> 
                ))}
                </div>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
            <div className="w-row">
              <div className="w-col w-col-2">
                <h4 className="event-office-title">Asia Pacific</h4>
              </div>
              <div className="w-col w-col-10">
                <div className="w-row">
                {data.allStrapiOffice.edges.map(document => (
                  <div key={document.node.id}>
                  {(document.node.region === "Asia Pacific") ?
                    <div className="w-col w-col-6">
                      <div className="office-content">
                        <h4 className="event-office-title">{document.node.name}</h4>
                        <p className="event-office-text">{document.node.address}<br />
                          {document.node.address_line_two}{document.node.address_line_two ? <br /> : <span />}
                          {document.node.city}{document.node.city ? ", " :" "}  
                          {document.node.state}{document.node.state ? ", " :" "}  
                          {document.node.zipcode}{document.node.zipcode ? ", " :" "} 
                          {document.node.country}
                        </p>
                        <p className="event-office-text">Tel: {document.node.phone}</p>
                      </div>
                    </div> : <span />}
                  </div> 
                ))}
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </section>

    </div>
    <Footer />
  </Layout>
)
export default AboutPage

export const pageQuery = graphql`  
  query AboutQuery {
    allStrapiAbout {
      edges {
        node {
          id
          description
          language
        }
      }
    }
    allStrapiOffice {
      edges {
        node {
          id
          name
          address
          address_line_two
          city
          state
          zipcode
          country
          phone
          website
          email
          region
        }
      }
    }
  }
`
